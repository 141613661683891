<template>
  <div class="service-copy-message">
    <el-form label-width="150px">
      <el-form-item label="公众号">
        <el-select
          v-model="accountChannelIds"
          style="width: 100%"
          placeholder="请选择"
          multiple
          clearable
          filterable
        >
          <el-option
            v-for="item in account"
            :key="item.id"
            :value="item.id"
            :label="item.channelName"
          >
            <span style="float: left">{{ item.channelName }}</span>
            <span style="float: right; margin-left: 15px; font-size: 13px"
              >{{ item.platformStr }} - {{ item.uname }}</span
            >
          </el-option>
        </el-select>
        <div class="store-channel">
          <el-tag
            v-for="(item, index) in storeChannel"
            :key="index"
            type="success"
            style="margin-right: 4px; cursor: pointer"
            @click="handleUserChannle(item)"
            >{{ item.channelName }}</el-tag
          >
        </div>
      </el-form-item>
      <el-form-item label="是否发送复制时间">
        <!-- <el-tooltip class="item" effect="dark" content="是否一起复制发送时间" placement="top-start">
          <span class="isCopyTime">是否一起复制发送时间:</span>
        </el-tooltip> -->
        <!-- <el-switch
          class="isCopyTimeSwitch"
          v-model="isCopyTime"
          @change="switchChangeHandler"
        >
        </el-switch>-->
        <el-radio-group v-model="isCopyTime" style="margin-left: 10px">
          <el-radio :label="1">无</el-radio>
          <el-radio :label="2">复制发送时间</el-radio>
          <el-radio :label="3">选择发送时间</el-radio>
        </el-radio-group>
        <el-date-picker
          v-if="isCopyTime === 3"
          style="margin-left: 10px"
          v-model="date"
          type="datetime"
          align="right"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="复制次数">
        <!-- <span class="copy-numbers">
          复制次数:
          <i class="iconfont icon-tishi" title="单次复制最多5次"></i>
        </span> -->
        <span>
          <el-input-number
            v-model="copyNum"
            placeholder
            :min="1"
            :max="5"
          ></el-input-number>
        </span>
      </el-form-item>
    </el-form>
    <span class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button @click="handleSubmit" type="primary" :loading="submitLoading"
        >确 定</el-button
      >
    </span>
  </div>
</template>

<script>
import { copyServiceMessage } from "@/api/service";
import { formatTime } from "../../../assets/js/utils";

export default {
  name: "CopyMesg",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Array,
      default: () => [],
    },
    accountList: Array,
    accountId: Number,
  },
  data() {
    return {
      accountChannelIds: [],
      account: [],
      submitLoading: false,
      isCopyTime: 2, // 默认否
      copyNum: 1,
      date: null,
      pickerOptions: {
        disabledDate(time) {
          return time < Date.now() - 1000 * 24 * 3600;
        },
      },
      defaultChannel: {},
      storeChannel: [],
    };
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.defaultChannel = this.accountList.find(
            (item) => item.id === this.accountId
          );
          this.account = this.accountList.filter(
            (item) => item.platform === this.defaultChannel.platform
          );
          this.storeChannel = [];
          if (
            this.$store.state.messageServerChannel.platform ==
            this.defaultChannel.platform
          ) {
            let tmpArr =
              this.$store.state.messageServerChannel.accountChannelIds;
            tmpArr.forEach((item1) => {
              let tmpItem = this.account.find((item2) => {
                return item2.id == item1;
              });
              this.storeChannel.push(tmpItem);
            });
          }
        }
      },
      immediate: true,
    },
    isCopyTime: {
      handler(newV) {
        this.date =
          newV === 3
            ? formatTime(new Date(Date.now() + 3600 * 24 * 1000))
            : null;
      },
    },
  },

  methods: {
    handleUserChannle(item) {
      if (!this.accountChannelIds.includes(item.id)) {
        this.accountChannelIds.push(item.id);
      }
    },
    handleSubmit() {
      const { accountChannelIds } = this;
      if (accountChannelIds && accountChannelIds.length) {
        this.submitLoading = true;
        copyServiceMessage(this.message.join(), {
          accountChannelIds,
          copyType: this.isCopyTime,
          copyNum: this.copyNum,
          ...(this.isCopyTime === 3 && { date: this.date }),
        })
          .then(() => {
            this.$message.success("复制成功");
            // 缓存复制的公众号
            this.$store.state.messageServerChannel = {
              platform: this.defaultChannel.platform,
              accountChannelIds: accountChannelIds,
            };
            this.$emit("refresh");
            this.handleClose();
          })
          .finally(() => {
            this.submitLoading = false;
            this.handleClose();
          });
      } else {
        this.$message.error("请选择公众号");
      }
    },
    handleClose() {
      this.accountChannelIds = [];
      this.$emit("close");
      this.isCopyTime = 2;
      this.copyNum = 1;
    },
    getDefaultTime() {
      return new Date(Date.now() + 3600 * 24 * 1);
    },
  },
};
</script>

<style scoped>
.copy-numbers {
  color: #1f2d3d;
  margin-right: 10px;
}
.isCopyTime {
  color: #1f2d3d;
  font-size: 14px;
}
.isCopyTimeSwitch {
  margin-left: 10px;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  margin-top: 40px;
  text-align: center;
}
</style>
