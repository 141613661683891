<template>
  <div>
    <div
      class="
        part
        border-radius-6
        box-shadow-light-grey
        padding-20
        height-calc-type1
      "
    >
      <div class="flex-between operation padding-bottom-20">
        <div>
          <el-button @click="getList">刷新数据</el-button>
          <el-button
            class="custom-button-margin-left"
            type="success"
            :disabled="selected.length === 0"
            @click="
              isCopy = true;
              copyMessage = [...selected];
            "
            >批量复制消息
          </el-button>
          <el-button
            class="custom-button-margin-left custom-button-margin-right"
            type="danger"
            :disabled="selected.length === 0"
            @click="handleDelete(selected)"
            >批量删除消息
          </el-button>
          <el-select
            class="margin-right-twentyFour"
            v-model="status"
            placeholder="状态"
            style="width: 140px"
            clearable
            @change="handlePageChange(1)"
          >
            <el-option label="未开始" :value="0"></el-option>
            <el-option label="进行中" :value="1"></el-option>
            <el-option label="已完成" :value="2"></el-option>
          </el-select>
          <el-date-picker
            class="margin-right-twentyFour"
            style="width: 200px"
            @change="handlePageChange(1)"
            v-model="tickTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="定时发送时间"
          >
          </el-date-picker>
          <el-input
            class="margin-right-twentyFour"
            v-model="copyRelationId"
            style="width: 200px"
            placeholder="请输入消息ID"
            clearable
            @clear="handleClear"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList()"
            ></el-button
          ></el-input>
        </div>
        <el-button
          type="primary"
          @click="handleDialogShow(null, 'isEdit')"
          icon="el-icon-plus"
          >新增客服消息</el-button
        >
      </div>
      <el-table
        class="table-service"
        v-loading="loading"
        :data="tableData"
        height="calc(100vh - 320px)"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="id" width="80" label="ID"> </el-table-column>
        <el-table-column prop="remark" min-width="150" label="备注">
          <template slot-scope="scope">
            <span>{{ scope.row.remark }}</span>
            <el-tag
              type="warning"
              v-if="scope.row.tip"
              style="margin-left: 10px"
              >9.9</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="msgType" label="消息类型" width="100">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.msgType === 'text'" type="info"
              >文本</el-tag
            >
            <el-tag v-else-if="scope.row.msgType === 'news'">图文</el-tag>
            <el-tag v-else-if="scope.row.msgType === 'voice'" type="success"
              >音频</el-tag
            >
            <el-tag v-else-if="scope.row.msgType === 'image'" type="success"
              >图片</el-tag
            >
            <el-tag v-else-if="scope.row.msgType === 'video'" type="success"
              >视频</el-tag
            >
            <el-tag
              v-else-if="scope.row.msgType === 'miniprogrampage'"
              type="success"
              >小程序卡片</el-tag
            >
            <el-tag v-else type="danger">未知</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="records" label="应发送条数" width="100">
        </el-table-column>
        <el-table-column prop="sendNum" label="已发送条数" width="100">
        </el-table-column>
        <el-table-column prop="status" label="发送状态" width="80">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0" type="warning">未开始</el-tag>
            <el-tag v-else-if="scope.row.status === 1">进行中</el-tag>
            <el-tag v-else-if="scope.row.status === 2" type="success"
              >已成功</el-tag
            >
            <el-tag v-else type="danger">其他</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="创建时间" width="160">
        </el-table-column>
        <el-table-column prop="tick" label="定时发送时间" min-width="160">
        </el-table-column>
        <el-table-column prop="startDate" label="发送开始时间" min-width="150">
        </el-table-column>
        <el-table-column prop="endDate" label="发送完成时间" min-width="150">
        </el-table-column>
        <el-table-column
          prop="action"
          width="240"
          fixed="right"
          class="test"
          label="操作"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="详情"
              placement="top-start"
            >
              <el-button
                type="info"
                icon="el-icon-document"
                circle
                @click="handleDialogShow(scope.row, 'showDetail')"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              placement="top-start"
            >
              <el-button
                type="primary"
                v-if="scope.row.status === 0"
                icon="el-icon-edit"
                circle
                @click="handleDialogShow(scope.row, 'isEdit')"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="发送客服消息"
              placement="top-start"
            >
              <el-button
                type="warning"
                v-if="scope.row.repeatSend"
                icon="el-icon-s-promotion"
                circle
                @click="handleSendMesg(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-dropdown
              size="medium"
              style="vertical-align: middle; margin-left: 10px"
              trigger="click"
              @command="(command) => handleRowClick(command, scope.row)"
            >
              <el-button class="button-small">
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="copy">复制消息</el-dropdown-item>
                <el-dropdown-item style="color: #f56c6c" command="delete"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- <el-drawer :visible.sync='isEdit'
    direction='rtl'
    size='100%'
    :modal-append-to-body="false"
    append-to-body> -->
    <edit
      :show="isEdit"
      :info="current"
      :moduleType="1"
      :account-id="accountChannelId"
      @close="isEdit = false"
      :account="channelList"
      @refresh="handlePageChange(1)"
    />
    <!-- <el-drawer
      title='客服消息详情'
      diretcion='rtl'
      size='850px'
      :visible.sync='showDetail'
    > -->
    <detail
      :show="showDetail"
      :info="current"
      @close="showDetail = false"
      :moduleType="1"
    />
    <!-- </el-drawer> -->
    <el-dialog title="复制消息" center append-to-body :visible.sync="isCopy">
      <copy-message
        :show="isCopy"
        :accountList="channelList"
        :message="copyMessage"
        :account-id="accountChannelId"
        @close="isCopy = false"
        @refresh="handlePageChange(1)"
      />
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getService,
  deleteMessage,
  sendServiceMessage,
} from "../../api/service";
import Detail from "./detail";
import CopyMessage from "./components/copyMesg";
import { messagePlatformList } from "@/assets/js/options"; //客服消息支持平台
export default {
  name: "Service",
  props: ["accountChannelId", "relationId"],
  data() {
    return {
      loading: false,
      status: null,
      page: 1,
      total: 0,
      pageSize: 20,
      tableData: [],
      isEdit: false,
      isCopy: false,
      current: null,
      showDetail: false,
      selected: [],
      copyMessage: [],
      tickTime: null,
      channelId: "",
      copyRelationId: null,
      messagePlatformList,
    };
  },
  computed: {
    channelList() {
      const { officialList } = this;
      return officialList.filter(
        (item) =>
          messagePlatformList.includes(item.platform) && item.status === 1
      );
    },
    ...mapGetters(["officialList"]),
  },
  beforeRouteEnter(to, form, next) {
    next((vm) => {
      vm.copyRelationId = vm.relationId;
      vm.getList();
    });
  },
  watch: {
    accountChannelId(newVal) {
      if (newVal) {
        this.handlePageChange(1);
      }
    },
    immediate: true,
  },
  methods: {
    handleSelectionChange(row) {
      this.selected = row.map((item) => item.id);
    },
    handleRowClick(type, data) {
      if (type === "delete") {
        this.handleDelete([data.id]);
      } else if (type === "copy") {
        this.copyMessage = [data.id];
        this.isCopy = true;
      }
    },
    handleDialogShow(item, prop) {
      this[prop] = true;
      this.current = item;
    },
    handleSendMesg(item) {
      this.$confirm("确定发送选中客服信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          sendServiceMessage(item.id).then(() => {
            this.$message.success("发送成功");
            this.getList();
          });
        })
        .catch(() => {});
    },
    handleDelete(data) {
      this.$confirm("此操作将永久删除该客服消息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteMessage(data.join()).then(() => {
            this.$message.success("删除成功");
            this.getList();
          });
        })
        .catch(() => {});
    },
    handlePageChange(page) {
      this.page = page;
      this.getList();
    },
    handleClear() {
      this.$emit("update:relationId", null);
      this.copyRelationId = null;
      this.page = 1;
      this.getList();
    },
    getList() {
      if (this.accountChannelId) {
        this.loading = true;
        getService({
          page: this.page,
          pageSize: this.copyRelationId ? 99999 : this.pageSize,
          accountChannelId: this.accountChannelId,
          status: this.status,
          tickTime: this.tickTime,
          serviceSendId: this.copyRelationId ? this.copyRelationId : null,
        })
          .then((res) => {
            this.tableData = res.list;
            this.total = res.total;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  components: {
    Edit: () => import("./edit"),
    Detail,
    CopyMessage,
  },
};
</script>

<style lang="scss" scoped>
.operation {
  background: #fff;
  // padding: 0 15px;
  // box-shadow: -2px -2px 10px rgba(180, 180, 180, 0.1);
}
.part {
  box-sizing: border-box;
  background: #fff;
  // box-shadow: -2px -2px 10px rgba(180, 180, 180, 0.1);
}
/deep/ .el-drawer {
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.table-service /deep/ .is-leaf {
  border-bottom: 0 !important;
  padding: 14px 0 !important;
}
</style>
