<template>
  <el-dialog
    :title="
      moduleType === 1
        ? '客服消息详情'
        : moduleType === 2
        ? '延迟回复详情'
        : '标签群发详情'
    "
    :visible.sync="show"
    center
    width="700px"
    top="2vh"
    append-to-body
    :before-close="handleClose"
  >
    <div class="service-detail">
      <el-form label-width="120px" class="form-container">
        <el-form-item label="公众号：">
          <span>{{ form.channelName }}</span>
        </el-form-item>
        <el-form-item label="定时时长：" v-if="moduleType === 2">
          <span
            >{{ timeoutObj.hour }}时{{ timeoutObj.minute }}分{{
              timeoutObj.second
            }}秒后</span
          >
        </el-form-item>
        <el-form-item label="备注：">
          <span>{{ form.remark || "无" }}</span>
        </el-form-item>
        <el-form-item label="消息类型：">
          <el-tag v-if="form.msgType === 'text'" type="info">文本</el-tag>
          <el-tag
            v-else-if="form.msgType === 'news' || form.msgType === 'mpnews'"
            >图文</el-tag
          >
          <el-tag v-else-if="form.msgType === 'voice'" type="success"
            >音频</el-tag
          >
          <el-tag v-else-if="form.msgType === 'image'" type="success"
            >图片</el-tag
          >
          <el-tag v-else-if="form.msgType === 'miniprogrampage'" type="success"
            >小程序卡片</el-tag
          >
          <el-tag v-else type="danger">未知</el-tag>
        </el-form-item>
        <el-form-item label="消息内容：">
          <div class="div-highlights" v-if="form.msgType === 'text'">
            {{ textStr }}
          </div>
          <div v-if="form.msgType === 'mpnews'">
            <!-- {{ material.title }} -->
            <div class="material-item" v-if="material">
              <ul class="article-box">
                <template v-if="material.title.includes('[{')">
                  <li
                    class="article-item"
                    v-for="(item2, index2) in JSON.parse(material.title)"
                    :key="index2"
                  >
                    <p>{{ item2.title }}</p>
                    <img :src="item2.url" />
                  </li>
                </template>
                <template v-else>
                  <span>{{ material.title }}</span>
                </template>
              </ul>
            </div>
          </div>
          <el-form v-if="form.msgType === 'news'">
            <el-form-item label="链接">
              <span style="margin-right: 10px">{{ newsForm.bookName }}</span>
            </el-form-item>
            <el-form-item>
              <div class="example">
                <div class="card">
                  <p class="mesg-title">{{ newsForm.title || "消息标题" }}</p>
                  <div class="detail">
                    <p>{{ newsForm.description || "描述文字" }}</p>
                    <template>
                      <img
                        :src="newsForm.picurl"
                        alt=""
                        v-if="newsForm.picurl"
                      />
                      <div class="image-slot" v-else>
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-form>
          <p v-if="form.msgType === 'voice'">{{ selectVoice.title || "无" }}</p>
          <template v-if="form.msgType === 'image'">
            <span v-if="selectImage.title.includes('{')">
              <p v-if="form.msgType === 'image'">
                {{ JSON.parse(selectImage.title).title || "无" }}
              </p>
              <img
                style="max-width: 200px; max-height: 200px"
                :src="JSON.parse(selectImage.title).url"
                alt=""
              />
            </span>
            <span v-else>
              <p>{{ selectImage.title || "无" }}</p>
            </span>
          </template>
          <el-form v-if="form.msgType === 'miniprogrampage'">
            <el-form-item>
              <p>
                <img :src="newsForm.novelName" style="width: 50px" alt="" />
              </p>
              <p>
                <span>标题：</span
                ><span style="margin-right: 10px">{{ newsForm.title }}</span>
              </p>
              <p>
                <span>appid：</span
                ><span style="margin-right: 10px">{{ newsForm.appid }}</span>
              </p>
              <p>
                <span>页面路径：</span
                ><span style="margin-right: 10px">{{ newsForm.pagepath }}</span>
              </p>
            </el-form-item>
          </el-form>
          <!-- <p v-if="form.msgType === 'image'">{{ selectImage.title || "无" }}</p> -->
        </el-form-item>
        <el-form-item label="预约发送：">
          <span>{{ form.tick || "无" }}</span>
        </el-form-item>
        <template v-if="moduleType === 3">
          <el-form-item label="标签类型：">
            <span v-if="form.type === 1">标签群发</span>
            <span v-if="form.type === 2">关注发送</span>
            <span v-if="form.type === 3">标签循环</span>
          </el-form-item>
          <el-form-item label="发送间隔：">
            <span>{{ form.subDay }}天</span>
          </el-form-item>
        </template>
        <el-form-item label="接收用户">
          <el-tag v-if="form.sendAll === 1" type="info">全部用户</el-tag>
          <el-tag v-if="form.sendAll === 0">标签用户</el-tag>
          <el-tag v-if="moduleType === 1" type="info" style="margin-left: 5px"
            >关注过滤时长：{{
              form.subscribeHours ? form.subscribeHours + "小时" : "无"
            }}</el-tag
          >
        </el-form-item>
        <el-form-item
          label="发送次数排除"
          v-if="form.minSendNum || form.maxSendNum"
        >
          <span v-if="!form.minSendNum && !form.maxSendNum">-</span>
          <template v-else>
            <span
              style="margin-right: 50px"
              v-if="!form.minSendNum && !form.maxSendNum"
              >无</span
            >
            <span style="margin-right: 50px" v-else>
              {{ form.minSendNum }} - {{ form.maxSendNum }}
              <span style="margin-left: 20px">次</span></span
            >
          </template>
        </el-form-item>

        <el-form-item
          label="关注时间"
          v-if="form.sendAll === 0 && (form.startDate || form.endDate)"
        >
          {{ form.startDate }} - {{ form.endDate }}
        </el-form-item>
        <el-form-item label="充值情况" v-if="form.sendAll === 0">
          <span v-if="form.rechargeType === 0">不限</span>
          <span v-else-if="form.rechargeType === 1"> 未充值</span>
          <span v-else>已充值</span>
        </el-form-item>

        <el-form-item
          label="充值间隔"
          v-if="form.sendAll === 0 && form.rechargeType === 2"
        >
          <span
            v-if="!form.rechargeTimeSpaceStart && !form.rechargeTimeSpaceEnd"
            >无</span
          >
          <span v-else-if="!form.rechargeTimeSpaceEnd">
            {{ form.rechargeTimeSpaceStart }} 天以上
          </span>
          <span v-else-if="!form.rechargeTimeSpaceStart">
            {{ form.rechargeTimeSpaceEnd }} 天以下
          </span>
          <span v-else>
            {{ form.rechargeTimeSpaceStart }} - {{ form.rechargeTimeSpaceEnd }}
            <span style="margin-left: 20px">天</span>
          </span>
        </el-form-item>
        <el-form-item
          label="剩余书币"
          v-if="form.sendAll === 0 && (form.maxKanDian || form.minKanDian)"
        >
          <span
            style="margin-right: 50px"
            v-if="form.maxKanDian || form.minKanDian"
            >{{ form.minKanDian ? form.minKanDian : "" }} -
            {{ form.maxKanDian ? form.maxKanDian : "" }}</span
          >
          <span v-else style="margin-right: 50px">无</span>
        </el-form-item>
        <el-form-item
          label="累计充值"
          v-if="form.rechargeType === 2 && form.sendAll === 0"
        >
          <span v-if="!form.minTotalRecharge && !form.maxTotalRecharge">
            无
          </span>
          <span v-else style="margin-right: 50px"
            >{{ form.minTotalRecharge }} - {{ form.maxTotalRecharge }}
            <span style="margin-left: 20px">元</span></span
          >
        </el-form-item>
        <el-form-item
          label="最后充值"
          v-if="form.rechargeType === 2 && form.sendAll === 0"
        >
          <span v-if="!form.minLastRechargeAmt && !form.maxLastRechargeAmt">
            无
          </span>
          <span v-else style="margin-right: 50px"
            >{{ form.minLastRechargeAmt }} - {{ form.maxLastRechargeAmt }}
            <span style="margin-left: 20px">元</span></span
          >
        </el-form-item>
        <el-form-item
          label="充值次数"
          v-if="
            form.rechargeType === 2 &&
            form.sendAll === 0 &&
            (form.minRechargeCount || form.maxRechargeCount)
          "
        >
          <span v-if="!form.minRechargeCount && !form.maxRechargeCount">-</span>
          <template v-else>
            <span
              style="margin-right: 50px"
              v-if="!form.minRechargeCount && !form.maxRechargeCount"
              >无</span
            >
            <span style="margin-right: 50px" v-else>
              {{ form.minRechargeCount }} - {{ form.maxRechargeCount }}
              <span style="margin-left: 20px">次</span></span
            >
          </template>
        </el-form-item>
        <el-form-item
          label="近7日充值"
          v-if="
            form.rechargeType === 2 &&
            form.sendAll === 0 &&
            (form.maxSevenRecharge || form.minSevenRecharge)
          "
        >
          <span v-if="!form.minSevenRecharge && !form.maxSevenRecharge">-</span>
          <template v-else>
            <span
              style="margin-right: 50px"
              v-if="!form.minSevenRecharge && !form.maxSevenRecharge"
              >无</span
            >
            <span style="margin-right: 50px" v-else>
              {{ form.minSevenRecharge }} - {{ form.maxSevenRecharge }}
              <span style="margin-left: 20px">元</span></span
            >
          </template>
        </el-form-item>
        <el-form-item
          label="是否vip"
          v-if="form.rechargeType === 2 && form.sendAll === 0"
        >
          <span v-if="form.isVip === 0">否</span>
          <span v-if="form.isVip === 1">是</span>
          <span v-if="form.isVip === 2">不限</span>
        </el-form-item>
        <el-form-item
          label="连续付费用户"
          v-if="form.rechargeType === 2 && form.sendAll === 0"
        >
          <span>{{ form.continuousRecharge ? "是" : "不限" }}</span>
        </el-form-item>
        <el-form-item
          label="RFM"
          v-if="form.rechargeType === 2 && form.sendAll === 0 && form.rfm"
        >
          <el-tag v-if="form.rfm.includes('0')" type="info">不限</el-tag>
          <el-tag
            v-if="form.rfm.includes('1')"
            class="margin-right-ten"
            type="success"
            >A类</el-tag
          >
          <el-tag
            v-if="form.rfm.includes('2')"
            class="margin-right-ten"
            type="warning"
            >B类</el-tag
          >
          <el-tag
            v-if="form.rfm.includes('3')"
            class="margin-right-ten"
            type="danger"
            >C类</el-tag
          >

          <!-- <el-tag v-if="form.rfm === 111">流失客户</el-tag>
          <el-tag v-if="form.rfm === 112">重要挽留客户</el-tag>
          <el-tag v-if="form.rfm === 121">一般维持客户</el-tag>
          <el-tag v-if="form.rfm === 122"> 重点唤回客户</el-tag>
          <el-tag v-if="form.rfm === 211"> 一般发展客户</el-tag>
          <el-tag v-if="form.rfm === 212"> 重点深耕客户</el-tag>
          <el-tag v-if="form.rfm === 221"> 潜力客户</el-tag>
          <el-tag v-if="form.rfm === 222"> 重要价值客户</el-tag> -->
        </el-form-item>
        <el-form-item
          label="优惠敏感度"
          v-if="form.rechargeType === 2 && form.sendAll === 0"
        >
          <el-tag v-if="form.couponLevel === 0" type="info">不限</el-tag>
          <el-tag v-if="form.couponLevel === 1">1级</el-tag>
          <el-tag v-if="form.couponLevel === 2">2级</el-tag>
          <el-tag v-if="form.couponLevel === 3">3级</el-tag>
          <el-tag v-if="form.couponLevel === 4">4级</el-tag>
          <el-tag v-if="form.couponLevel === 5">5级</el-tag>
        </el-form-item>
        <el-form-item label="性别" v-if="form.sendAll === 0">
          <span v-if="form.sex === 0">不限</span>
          <span v-else-if="form.sex === 1">男</span>
          <span v-else>女</span>
        </el-form-item>
        <el-form-item
          label="所在地区"
          v-if="form.sendAll === 0 && (form.city || form.province)"
        >
          <span v-if="!form.province && !form.city">-</span>
          <template v-else>
            <span style="margin-right: 50px" v-if="!form.province && !form.city"
              >无</span
            >
            <span style="margin-right: 50px" v-else>
              {{ form.province }} - {{ form.city }}
              <span style="margin-left: 20px"></span
            ></span>
          </template>
        </el-form-item>
        <!-- 新添互动类型 -->
        <el-form-item v-if="moduleType === 3" label="互动类型">
          <el-tag v-if="form.tagType === 0" type="info">全部</el-tag>
          <el-tag v-if="form.tagType === 1">活跃</el-tag>
          <el-tag v-if="form.tagType === 2">不活跃</el-tag>
        </el-form-item>
        <!-- 新增基础、统计、挖掘属性 -->
        <!-- <el-form-item label="接收用户">
        <el-tag v-if="form.sendAll === 1" type="info">全部用户</el-tag>
        <el-tag v-if="form.sendAll===0">标签用户</el-tag>
        <el-form  label-width="95px" v-if="form.sendAll === 0">
          <el-form-item label="性别">
            <span v-if="form.sex === 0">不限</span>
            <span v-else-if="form.sex===1">男</span>
            <span v-else>女</span>
          </el-form-item>
          <el-form-item label="充值情况">
            <span v-if="form.rechargeType === 0">不限</span>
            <span v-else-if="form.rechargeType===1">未充值</span>
            <span v-else>已充值</span>
          </el-form-item>
          <el-form-item label="关注时间">
            {{form.startDate}} - {{form.endDate}}
          </el-form-item>
          <el-form-item label="充值间隔">
            <span v-if="(!form.rechargeTimeSpaceStart) && (!form.rechargeTimeSpaceEnd)">无</span>
            <span v-else-if="!form.rechargeTimeSpaceEnd">{{form.rechargeTimeSpaceStart}}天以上 </span>
            <span v-else-if="!form.rechargeTimeSpaceStart">{{form.rechargeTimeSpaceEnd}}天以下 </span>
            <span v-else>{{form.rechargeTimeSpaceStart}} - {{form.rechargeTimeSpaceEnd}}天</span>
          </el-form-item>
          <el-form-item label="书籍">
            <span>{{form.bookName || '无'}}</span>
          </el-form-item>
        </el-form>
      </el-form-item> -->
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getServiceDetail,
  getDelayMessageDetail,
  // getHourMessageDetail,
  getSendtTagDetatl,
} from "../../api/service";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "edit",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    account: {
      type: Array,
      default: () => [],
    },
    info: Object,
    moduleType: Number,
  },
  data() {
    return {
      testId: null,
      estimateNum: null,
      form: {},
      textStr: "", // 文本内容
      textIndex: 0,
      insertBook: false,
      newsForm: {},
      formLoading: false,
      selectVoice: {},
      selectImage: {},
      showFlag: false,
      timeoutObj: {
        hour: 0,
        minute: 0,
        second: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["officialList"]),
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal && this.info) {
          this.initData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    initData() {
      this.formLoading = true;
      if (this.moduleType === 1) {
        getServiceDetail(this.info.id)
          .then((res) => {
            const { content, novelName, ...other } = res;
            this.form = other;

            const contentObj = JSON.parse(content);
            if (other.msgType === "text") {
              this.textStr = contentObj.content;
            } else if (other.msgType === "news") {
              const data = contentObj.articles[0];
              this.newsForm = { ...data, bookName: novelName };
            } else if (other.msgType === "voice") {
              this.selectVoice = {
                mediaId: contentObj.media_id,
                title: novelName,
              };
            } else if (other.msgType === "image") {
              this.selectImage = {
                mediaId: contentObj.media_id,
                title: novelName,
              };
            } else if (other.msgType === "miniprogrampage") {
              this.newsForm = {
                novelName: novelName,
                title: contentObj.title,
                appid: contentObj.appid,
                pagepath: contentObj.pagepath,
              };
            }
            // ;
            const target = this.officialList.find(
              (item) => item.id === res.accountChannelId
            );
            this.showFlag =
              target.platform === 3 &&
              this.moduleType === 1 &&
              this.form.sendAll === 0 &&
              this.form.rechargeType === 2;
          })
          .finally(() => {
            this.formLoading = false;
          });
      }
      if (this.moduleType === 2) {
        getDelayMessageDetail(this.info.id)
          .then((res) => {
            const { content, novelName, sendOffset, ...other } = res;
            this.form = other;
            const contentObj = JSON.parse(content);
            if (other.msgType === "text") {
              this.textStr = contentObj.content;
            } else if (other.msgType === "news") {
              const data = contentObj.articles[0];
              this.newsForm = { ...data, bookName: novelName };
            } else if (other.msgType === "voice") {
              this.selectVoice = {
                mediaId: contentObj.media_id,
                title: novelName,
              };
            } else if (other.msgType === "image") {
              this.selectImage = {
                mediaId: contentObj.media_id,
                title: novelName,
              };
            } else if (other.msgType === "miniprogrampage") {
              this.newsForm = {
                novelName: novelName,
                title: contentObj.title,
                appid: contentObj.appid,
                pagepath: contentObj.pagepath,
              };
            }
            this.timeoutObj = {
              hour: sendOffset ? Math.floor(sendOffset / 3600) : 0,
              minute: sendOffset ? Math.floor((sendOffset % 3600) / 60) : 0,
              second: sendOffset ? Math.floor((sendOffset % 3600) % 60) : 0,
            };
          })
          .finally(() => {
            this.formLoading = false;
          });
      }
      if (this.moduleType === 3) {
        getSendtTagDetatl(this.info.id)
          .then((res) => {
            const { contents, title, mediaId, ...other } = res;
            this.form = other;
            if (other.msgType === "text") {
              this.textStr = contents;
            } else {
              this.material = { title, mediaId };
            }
          })
          .finally(() => {
            this.formLoading = false;
          });
      }
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__wrapper {
  overflow: hidden;
}
/deep/ .el-dialog__body {
  padding-bottom: 0;
}

.service-detail {
  box-sizing: border-box;
  padding: 20px;
  height: 780px;
  overflow: auto;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  // margin-top:20px;
  text-align: center;
}
.form-container {
  max-height: 800px;
  // overflow: auto;
  padding-right: 10px;
}

.example {
  width: 375px;
  background-color: #f1f1f1;
  padding: 10px;
}

.card {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
}

.mesg-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-weight: bold;
  line-height: 20px;
}

.detail {
  display: flex;
  align-items: center;
}

.detail p {
  flex: 1;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
}

.detail img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: #f5f7fa;
  color: #909399;
}
.div-highlights {
  margin-top: 10px;
  width: auto !important;
  height: auto !important;
  border-color: transparent !important;
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  // color: transparent !important;
  color: #000;
  line-height: 1;
  // overflow: hidden !important;
}

// 图文回显
.material-item {
  margin-top: 5px;
  width: 256px;
  height: 380px;
  padding: 10px;
  margin-right: 20px;
  box-shadow: 0 1px 6px 0 #ccc;
  border-radius: 5px;
  position: relative;
  .article-box {
    position: relative;
    .article-item:first-child {
      width: 236px;
      height: 100px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        margin-left: 0;
      }
      p {
        position: absolute;
        bottom: 5px;
        left: 5px;
        width: 226px;
        height: 30px;
        line-height: 30px;
        padding-left: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
    .article-item {
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 30px;
        height: 30px;
        background: #666;
        margin-left: 15px;
      }
      p {
        flex: 1;
        display: table-cell;
        vertical-align: middle;
        white-space: normal;
        word-break: break-all;
        line-height: 30px;
        height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
        font-size: 14px;
      }
    }
  }
}
</style>
